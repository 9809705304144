<template>
    <div class="loader"></div>
</template>

<style>
    .loader {
        height: 5px;
        width: 100% !important;
        --c: no-repeat linear-gradient(#0869d8 0 0);
        background: var(--c), var(--c), #dedddd;
        background-size: 60% 100%;
        animation: l16 3s infinite;
        position: fixed;
        bottom: 75px;
        left: 0;
    }

    @keyframes l16 {
        0% {
            background-position: -150% 0, -150% 0
        }

        66% {
            background-position: 250% 0, -150% 0
        }

        100% {
            background-position: 250% 0, 250% 0
        }
    }
</style>
