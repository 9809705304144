<template>

    <div class="flex md12">
        <div class="row">
            <div class="flex md5">
                <center class="center-point">
                    <iframe v-if="this.imageData.name.split('.')[1] == 'pdf' || this.imageData.name.split('.')[1] == 'csv'" :src="this.bufferData" width="300px" height="300px"></iframe>
                    <img
                        v-else
                        :src="this.bufferData"
                        height="300"
                        width="300"
                    />
                </center>
            </div>

            <div class="flex md5">
                <div>
                    <br><br><br>
                    <div class="header-preview"><b>File Details</b></div>
                    <br>
                    <div class="row">
                        <div><b>Document Type :</b></div>
                        <div class="ml-3 top-set">{{ this.imageData.document }}</div>
                    </div><br>
                    <div class="row">
                        <div><b>Name :</b></div>
                        <div class="ml-3 top-set">{{ this.imageData.name.split('.')[0] }}</div>
                    </div><br>
                    <div class="row">
                        <div><b>Type :</b></div>
                        <div class="ml-3 top-set">{{ this.imageData.type }}</div>
                    </div><br>
                    <div class="row">
                        <div><b>Size :</b></div>
                        <div class="ml-3 top-set">{{ this.imageData.size }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props:{
            bufferData: String,
            imageData: Object
        }
    }
</script>

<style>
    .header-preview {
        font-size: 30px;
    }

    .top-set {
        margin-top: 2px;
    }
</style>