import { createWebHistory, createRouter } from 'vue-router';
import { authGuard } from './auth-guard';
import config from '@/router/config.json';
import axios from 'axios';

let route_json = {
	'Identity': '/identity_verification',
	'BusinessDetails': '/user_verification',
	'License': '/license',
	'Bundles': '/bundles',
	'Cdn': '/cdn',
	'OsInstallation': '/os',
	'PlatformAgreement': '/platform_agreement',
	'MsoAgreement': '/mso_agreement',
	'Mode': '/mode',
	'Application': '/app',
	'Payment': '/payment',
	'TrialEnabled': '/ordered',
	'OnboardingCompleted': '/ordered'
};

const routes = [
	{ path: '/:catchAll(.*)', redirect: { name: 'Login' } },
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/components/layout/AuthLayout.vue')
	}, {
		path: '/admin',
		name: 'AppLayout',
		beforeEnter: authGuard,
		component: () => import('@/components/layout/AppLayout.vue'),
		children: [
			{
				path: '/identity_verification',
				name: 'Identity',
				component: () => import('@/components/pages/Identity.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/signing_authorization',
				name: 'SigningAuthorization',
				component: () => import('@/components/pages/SigningAuthorization.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/license',
				name: 'License',
				component: () => import('@/components/pages/License.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/business_documents',
				name: 'BusinessDocument',
				component: () => import('@/components/pages/BusinessDocument.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/bundles',
				name: 'Bundles',
				component: () => import('@/components/pages/Bundles.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/cdn',
				name: 'Cdn',
				component: () => import('@/components/pages/Cdn.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/app',
				name: 'Application',
				component: () => import('@/components/pages/Application.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/mso_agreement',
				name: 'MsoAgreement',
				component: () => import('@/components/pages/Esign.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/po',
				name: 'PoGeneration',
				component: () => import('@/components/pages/PoGeneration.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/payment',
				name: 'Payment',
				component: () => import('@/components/pages/Payment.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/user_verification',
				name: 'BusinessDetails',
				component: () => import('@/components/pages/User.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/platform_agreement',
				name: 'PlatformAgreement',
				component: () => import('@/components/pages/Platform.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/os',
				name: 'OsInstallation',
				component: () => import('@/components/pages/Rediness.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/mode',
				name: 'Mode',
				component: () => import('@/components/pages/Mode.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/ordered',
				name: 'TrialEnabled',
				component: () => import('@/components/pages/Ordered.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}, {
				path: '/ordered',
				name: 'OnboardingCompleted',
				component: () => import('@/components/pages/Ordered.vue'),
				beforeEnter: (to, from) => {
					checkRoute(to)
				}
			}
		]
	}
];

const checkRoute = async (data) => {
	let get_status = await axios.get(config.host + 'customer/get_status');
	let status = get_status.data.process_status;
	if(Object.keys(data.query).length > 0) router.replace(data.fullPath);
	else router.replace(`${route_json[status]}`);
};

const router = createRouter({
	history: createWebHistory(),
	hash: false,
	routes,
});

export default router;